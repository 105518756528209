.container{
  position: relative;
}
.tooltip {
  position: absolute;
  color: #fff;
  padding: 10px;
  background: rgba(0,0,0,0.8);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.apartmentPopup {
  padding: 5px;
  position: absolute;
  color: #000;
  background: white;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 10px;
  z-index: 999;
  max-width: 300px;
  width: 300px;

  .apartmentAxonosImg {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
.spinner-load{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
