@import "~bootstrap/scss/bootstrap";

$primary: #85c77b;
$secondary: #4d89ad;
$room-0P: #4AC435FF;
$room-1P: #4ac435;
$room-2P: #BB8FCE;
$room-3P: #f8c471;
$room-4P: #ec7063;
$room-5P: #3498db;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;